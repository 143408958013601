/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
import { Card, Stack, Container, Typography, CardActions, CardMedia, Button, MenuItem } from '@mui/material';
import Swal from 'sweetalert2';
import Iconify from '../../components/iconify';
import { instance } from '../../config/Http';
import ViewUpcomingModal from './ViewUpcomingModal';
import ViewUpcomingModalEdit from './ViewUpcomingModalEdit';

export default function ViewUpcoming() {
  const [USERLIST, setUSERLIST] = useState([]);
  const [updatedBlogList, setUpdatedBlogList] = useState([]);
  const [handleButton, setHandleButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [openedit, setOpenedit] = useState(false);
  const [EditData, setEditData] = useState(null);
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseedit = () => setOpenedit(false);
  const handleOpenedit = (blog) => {
    setEditData(blog);
    setOpenedit(true);
  };

  const user = useCallback(() => {
    instance
      .get('admin/view_upcoming')
      .then((response) => {
        setUSERLIST(response?.data?.data);
        setUpdatedBlogList(response?.data?.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    user();
  }, [user]);

  const UpdateList = () => {
    setHandleButton(true);
    const Data = new FormData();
    updatedBlogList.forEach((e, index) => {
      Data.append(`images[${index}][id]`, e.id);
      Data.append(`images[${index}][position]`, index + 1);
    });
    instance
      .post('admin/upcoming/sorting', Data)
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire('Good job!', 'Sorted Successfully', 'success');
          user();
        } else {
          Swal.fire('Something Went Wrong', 'Failed', 'error');
        }
      })
      .catch((error) => {
        Swal.fire('Something Went Wrong', error?.message, 'error');
      })
      .finally(() => setHandleButton(false));
  };

  const delet = (id) => {
    instance
      .get(`admin/upcoming/delete/${id}`)
      .then((response) => {
        if (response?.data.status) {
          Swal.fire('Good job!', 'Upcoming Event deleted successfully', 'success');
          user();
        }
      })
      .catch((error) => Swal.fire('Something Went Wrong!', error?.message, 'error'));
  };

  const handleDragStart = (index) => {
    setDraggedItemIndex(index);

  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (targetIndex) => {
    if (draggedItemIndex === null || draggedItemIndex === targetIndex) return;

    const reorderedList = [...updatedBlogList];
    const [movedItem] = reorderedList.splice(draggedItemIndex, 1);
    reorderedList.splice(targetIndex, 0, movedItem);

    setUpdatedBlogList(reorderedList);
    setDraggedItemIndex(null);
  };

  return (
    <>
      <Helmet>
        <title> Upcoming Event | Ole Brass Rail </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Upcoming Events</Typography>
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{ backgroundColor: '#D32D0B' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Image
          </Button>
        </Stack>

        <Button
          disabled={handleButton}
          onClick={UpdateList}
          variant="contained"
          sx={{ backgroundColor: '#D32D0B', margin: '10px' }}
        >
          Update Sort
        </Button>

        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
          {updatedBlogList.map((blog, index) => (
            <div
              key={blog.id}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}
              className="card p-4 shadow-lg bg-white rounded-lg "
              style={{cursor:'grab'}}
            >
              <Card sx={{ maxWidth: 400 }}>
                <CardMedia sx={{ height: 350 }} image={blog?.image} title="Upcoming Event" />
                <CardActions>
                  <Button size="small">{blog?.created_at?.slice(0, 10)}</Button>
                  <MenuItem sx={{ color: 'error.main' }} onClick={() => delet(blog?.id)}>
                    <Iconify icon={'eva:trash-2-outline'} />
                  </MenuItem>
                  <MenuItem sx={{ color: 'error.main' }} onClick={() => handleOpenedit(blog)}>
                    Edit
                  </MenuItem>
                </CardActions>
              </Card>
            </div>
          ))}
        </div>

        <ViewUpcomingModal open={open} setOpen={setOpen} handleClose={handleClose} user={user} />
        <ViewUpcomingModalEdit
          EditData={EditData}
          openedit={openedit}
          setOpenedit={setOpenedit}
          handleCloseedit={handleCloseedit}
          user={user}
        />
      </Container>
    </>
  );
}
